# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

$ ->

  #---------------------
  # Functions
  #---------------------

  openStripe = (e) ->
    e.preventDefault()
    handler.open({
      description: button.attr('data-description'),
      amount: button.attr('data-amount')
      })
    # console.log('triggered')

  amountHasChanged = ->
    amount = $(this).val()
    updateDonationAmount(amount)

  updateDonationAmount = (amount) ->
    price_in_cent = amount * 100
    price_in_dollars = amount / 1.00
    new_description = "Donation of $"+price_in_dollars
    button.attr('data-description', new_description)
    button.attr('data-amount', price_in_cent)

  #---------------------
  # On page load
  #---------------------

  button = $('#custom-stripe-button')
  form = $('#new_donation')
  if (typeof StripeCheckout != 'undefined')
    handler = StripeCheckout.configure({
      key: button.attr('data-key'),
      image: button.attr('data-image'),
      name: 'The Butterfly Club',
      currency: 'AUD',
      token: (token) ->
        button.prop('disabled', true)
        form.append($('<input type="hidden" name="stripeToken" />').val(token.id))
        form.append($('<input type="hidden" name="stripeEmail" />').val(token.email))
        form.get(0).submit()
    })
  button.prop('disabled', false)

  #---------------------
  # Hooks
  #---------------------

  $('#custom-stripe-button').on('click', openStripe)
  $('#donation-amount').on('change keyup keydown', amountHasChanged)