(function(){
    document.addEventListener("DOMContentLoaded", function() {
        console.log("DOMContentLoaded detected by subject locator");
        if (document.querySelectorAll("#with_subject_locator").length) {

            // get the img tag
            var img = document.getElementById('with_subject_locator');

            // wait for the image to load
            img.onload = function() {
                // get the form fields, this is where the output goes and they are used to
                // place the circle on load
                formInputX = document.getElementById('production_img_subject_x');
                formInputY = document.getElementById('production_img_subject_y');
                // get the parent element of the image
                var box = img.parentElement;
                // get the padding of the parent element, needed to exclude it from 
                // the click coordinates and to position the circle correctly
                var style = window.getComputedStyle(box);
                var boxPaddingLeft = parseInt(style.paddingLeft);
                var boxPaddingTop = parseInt(style.paddingTop);

                // create a div element to represent the circle and append it to the parent element
                const circle = document.createElement('div');            
                circle.classList.add('circle');
                box.appendChild(circle);
                // ensure position is relative and box sizing is border-box
                // so that the circle can be positioned absolutely within the box
                box.style.position = 'relative';
                box.style.boxSizing = 'border-box';
                // set the circle position to absolute so it can be positioned within the box
                circle.style.position = 'absolute'
                // set the circle dimensions
                circle.style.width = '100px';
                circle.style.height = '100px';
                // make the circle half transparent
                circle.style.opacity = '0.5';
                // give the circle a black outer border
                circle.style.border = '2px solid black';
                // make the circle a... circle
                circle.style.borderRadius = '50%';
                // make the circle red
                circle.style.backgroundColor = 'red';
                // position the circle either:
                // - if coordinates already exist, at those coordinates; or
                // - in the center of the image
                if (formInputX.value) {
                    circle.style.left = 
                        boxPaddingLeft
                        + (parseFloat(formInputX.value) * img.offsetWidth)
                        - (circle.offsetWidth / 2) + 'px';
                } else {
                    circle.style.left = 
                        boxPaddingLeft 
                        + (img.offsetWidth / 2)
                        - (circle.offsetWidth / 2) + 'px';
                }

                if (formInputY.value) {
                    circle.style.top = 
                        boxPaddingTop
                        + (parseFloat(formInputY.value) * img.offsetHeight)
                        - (circle.offsetHeight / 2) + 'px';
                } else {
                    circle.style.top = 
                        boxPaddingTop 
                        + (img.offsetHeight / 2)
                        - (circle.offsetHeight / 2) + 'px';
                }

                // formInputX.value ? circle.style.left = boxPaddingLeft + parseInt(formInputX.value) - (circle.offsetWidth / 2) + 'px' : circle.style.left = boxPaddingLeft + (img.offsetWidth / 2) - (circle.offsetWidth / 2) + 'px';
                // formInputY.value ? circle.style.top = boxPaddingTop + parseInt(formInputY.value) - (circle.offsetHeight / 2) + 'px' : circle.style.top = boxPaddingTop + (img.offsetHeight / 2) - (circle.offsetHeight / 2) + 'px';

                
                // listen for clicks on the box and move the circle to the click location
                box.addEventListener('click', function(e) {
                    var x = e.clientX - box.getBoundingClientRect().left;
                    var y = e.clientY - box.getBoundingClientRect().top;
                    circle.style.left = x - (circle.offsetWidth / 2) + 'px';
                    circle.style.top = y - (circle.offsetHeight / 2) + 'px';

                    // output the new coordinates, as % of total image
                    x = x - boxPaddingLeft;
                    x < 0 ? x = 0 : x = x;
                    x > img.offsetWidth ? x = img.offsetWidth : x = x;
                    x = x / img.offsetWidth;
                    
                    y = y - boxPaddingTop;
                    y > img.offsetHeight ? y = img.offsetHeight : y = y;
                    y < 0 ? y = 0 : y = y;
                    y = y / img.offsetHeight;
                    

                    // THE OUTPUT
                    formInputX.value = x;
                    formInputY.value = y;
                });
            }

            // If the image is already loaded before this script runs
            if (img.complete) {
                img.onload();
            }
        }
    });
})();
