# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/


$ ->
  # Functions

  # Reports (Simone)
  toggleSettled = ->
    element = $(@)
    series_id = element.attr('data-series-id')
    $.ajax({
      url: '/private/reports/mark_as_settled/'+series_id,
      type: 'PUT'
    })

  $('#series-table').on('change', '.live-checkbox', toggleSettled)

