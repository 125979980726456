// // This file is automatically compiled by Webpack, along with any other files
// // present in this directory. You're encouraged to place your actual application logic in
// // a relevant structure within app/javascript and only use these pack files to reference
// // that code so it'll be compiled.

// application.js

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

try {
    console.log("Loading jQuery");
    require("jquery");

    console.log("Loading Bootstrap3");
    require("../legacy/bootstrap3");

    console.log("Loading nested_form");
    require("src/nested_form");

    console.log("Loading productions");
    require("../legacy/productions");

    console.log("Loading reports");
    require("../legacy/reports");

    console.log("Loading vouchers");
    require("../legacy/vouchers");

    console.log("Loading textarea_autoresize");
    require("../legacy/textarea_autoresize");

    console.log("Loading donations");
    require("../legacy/donations");

    console.log("Loading subject_locator");
    require("src/subject_locator");

    console.log("Loading smart_crop");
    require("src/smart_crop");

    Rails.start();
    ActiveStorage.start();
} catch (error) {
    console.error("Error loading scripts:", error);
}