import moment from "moment-mini";

$ ->
  # Functions

  # Productions (index)
  toggleLive = ->
    element = $(@)
    production_id = element.attr('data-production-id')
    $.ajax({
      url: '/private/productions/'+production_id+'/live',
      type: 'PUT'
    })

  updateCopyLink = ->
    prodID = $(@).find(':selected').val()
    $('#copy-existing').attr('href', '/private/productions/'+prodID+'/duplicate')

  # Performances
  displayWeekdays = ->
    $('.perf_datetime').each ->
      weekday = pickWeekday($(this).val())
      $(this).parent('td').siblings('.weekday').html('<span style="line-height:34px">'+weekday+' </span>')

  updateWeekday = ->
    weekday = pickWeekday($(this).val())
    $(this).parent('td').siblings('.weekday').html('<span style="line-height:34px">'+weekday+' </span>')

  pickWeekday = (day) ->
    date = new Date(day)
    weekday = weekdays[ date.getDay() ]
    return weekday

  copyTimes = (e) ->
    e.preventDefault()
    time = $('#perf_time_source').val()
    $('.perf_datetime').each ->
      d = new Date($(this).val())
      $(this).val(moment(d.setHours(time.slice(0,2),time.slice(3,5),0)).format().slice(0,19))

  copyDates = (e) ->
    e.preventDefault()
    source = new Date($('#perf_date_source').val())
    $('.perf_datetime').each ->
      target = new Date($(this).val())
      target.setFullYear(source.getFullYear())
      target.setMonth(source.getMonth())
      target.setDate(source.getDate())
      $(this).val(moment(target).format().slice(0,19))
      source.setDate(source.getDate() + 1)
    displayWeekdays()

  addPerformance = (e) ->
    e.preventDefault()
    # get the last perf index value
    lastRow = $('#performances tr').last()
    lastRowID = parseInt(lastRow.attr('id'))
    # value++ and append the new row to the table, or 0 if table empty
    newRowID = ++lastRowID || 0
    # try to get the date of the TR before this row
    datetime = new Date(lastRow.find('.perf_datetime').val())
    # Determine if date is valid; if not, the table is empty.
    is_valid = true unless isNaN( datetime.getTime())
    # If the date is valid add a day, otherwise default to today's date
    datetime.setDate(datetime.getDate() + 1) if is_valid
    datetime = new Date() unless is_valid
    # If the date is valid copy the time from the prev TR
    time = $('#perf_time_source').val() unless is_valid
    # Replace the time in datetime with the time we just worked out
    datetime.setHours(time.slice(0,2), time.slice(3,5), 0) unless is_valid
    # Format datetime
    datetime = moment(datetime).format().slice(0,19)
    # build HTML for new row
    newRow = '<tr id="'+newRowID+'">
            <td></td>
            <td class="weekday"></td>
            <td><input class="perf_datetime" id="production_performances_attributes_'+newRowID+'_datetime" name="production[performances_attributes]['+newRowID+'][datetime]" type="datetime-local" value="'+datetime+'" /></td>
            <td><input id="production_performances_attributes_'+newRowID+'_image" name="production[performances_attributes]['+newRowID+'][image]" type="file" /></td>
            <td></td>
            <td><button class="removePerformance btn btn-small">Remove</button></td>
          </tr>'
    $('#performances').append(newRow)
    displayWeekdays()

  removePerformance = ->
    $(@).closest('tr').remove()

  # Prices
  addPrice = (e) ->
    e.preventDefault()
    addSingleprice('', '', '')
    # # get the last price # value
    # lastRow = $('#prices tr').last()
    # lastRowID = parseInt(lastRow.attr('id'))
    # # console.log "Last row ID: " + lastRowID
    # # get the last perf date
    # # value++ and append the new row to the table
    # newRowID = ++lastRowID || 0
    # newRow = '<tr id="'+newRowID+'"><td><input id="production_pricess_attributes_'+newRowID+'_label" name="production[prices_attributes]['+newRowID+'][label]" type="text" /></td>
    #         <td><input id="production_prices_attributes_'+newRowID+'_cost" name="production[prices_attributes]['+newRowID+'][cost]" type="number"/></td>
    #         <td><input id="production_prices_attributes_'+newRowID+'_min" name="production[prices_attributes]['+newRowID+'][min]" type="number" /></td>
    #         <td></td>
    #         <td>
    #         <button class="removePrice btn btn-small">Remove</button>
    #         </td>
    #       </tr>'
    # $('#prices').append(newRow)

  removePrice = ->
    $(@).closest('tr').remove()

  addPriceStructureA = (e) ->
    e.preventDefault()
    addSingleprice('Full', 4500, 1)
    addSingleprice('Concession', 4000, 1)
    addSingleprice('Member', 3800, 1)
    addSingleprice('Group (6+)', 3800, 6)
    addSingleprice('Early Bird', 3400, 1)
    addSingleprice('Broke Butterfly', 1500, 1)

  addPriceStructureB = (e) ->
    e.preventDefault()
    addSingleprice('Full', 3900, 1)
    addSingleprice('Concession', 3500, 1)
    addSingleprice('Member', 3100, 1)
    addSingleprice('Group (6+)', 3100, 6)
    addSingleprice('Early Bird', 2900, 1)
    addSingleprice('Broke Butterfly', 1500, 1)

  addSingleprice = (label, cost, min) ->
    lastRow = $('#prices tr').last()
    lastRowID = parseInt(lastRow.attr('id'))
    newRowID = ++lastRowID || 0
    newRow = '<tr id="'+newRowID+'"><td><input id="production_pricess_attributes_'+newRowID+'_label" name="production[prices_attributes]['+newRowID+'][label]" type="text" value="'+label+'" /></td>
            <td><input id="production_prices_attributes_'+newRowID+'_cost" name="production[prices_attributes]['+newRowID+'][cost]" type="number" value="'+cost+'"/></td>
            <td><input id="production_prices_attributes_'+newRowID+'_min" name="production[prices_attributes]['+newRowID+'][min]" type="number" value="'+min+'" /></td>
            <td></td>
            <td>
            <button class="removePrice btn btn-small">Remove</button>
            </td>
          </tr>'
    $('#prices').append(newRow)

  # Utilities
  weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  # Bindings

  # On Load
  displayWeekdays()

  # Productions
  $('#productions-table').on('change', '.live-checkbox', toggleLive)
  $('#existing-productions').on('change', updateCopyLink)

  # Performances
  $('#performances').on('change', '.perf_datetime', updateWeekday)
  $('#addPerformance').on('click', addPerformance)
  $('#copyTimes').on('click', copyTimes)
  $('#copyDates').on('click', copyDates)
  $('#performances').on('click', '.removePerformance', removePerformance)

  # Prices
  $('#addPrice').on('click', addPrice)
  $('#addPriceStructureA').on('click', addPriceStructureA)
  $('#addPriceStructureB').on('click', addPriceStructureB)
  $('#prices').on('click', '.removePrice', removePrice)
