// src/smart_crop.js

console.log("smart_crop.js is being loaded");

function initSmartCrop() {
    console.log("Initializing smart crop");
    try {
        const MIN_WIDTH = 70; // the arbitrary min-width of the image

        const images = document.querySelectorAll(".s18-individual-listing-image");
        let loadedCount = 0;

        function checkAllImagesLoaded() {
            loadedCount++;
            console.log(`Loaded count: ${loadedCount}/${images.length}`);
            if (loadedCount === images.length) {
                console.log("All images loaded");
                images.forEach(image => {
                    const Ps = parseFloat(image.dataset.ps);
                    if (isNaN(Ps)) {
                        console.log(`Ps value is NaN for image ${image.src}`);
                        return;
                    }

                    const naturalW = image.naturalWidth;
                    const naturalH = image.naturalHeight;
                    if (!naturalW || !naturalH) {
                        console.log(`Natural dimensions not available for image ${image.src}`);
                        return;
                    }

                    const currentH = image.height;
                    const Wi = naturalW * (currentH / naturalH);
                    const Wv = image.width;

                    if ((Ps * Wi) <= (MIN_WIDTH / 2)) {
                        image.style.objectPosition = `0 50%`;
                    } else if ((Ps * Wi) >= (Wi - (MIN_WIDTH / 2))) {
                        image.style.objectPosition = `100% 50%`;
                    } else {
                        const Pt = calculatePt(Ps, Wi, Wv, MIN_WIDTH);
                        image.style.objectPosition = `${Pt * 100}% 50%`;
                    }
                });
            }
        }

        function handleImageEvent(event) {
            checkAllImagesLoaded();
            event.target.removeEventListener("load", handleImageEvent);
            event.target.removeEventListener("error", handleImageEvent);
        }

        images.forEach(function(img) {
            if (img.complete && img.naturalWidth && img.naturalHeight) {
                checkAllImagesLoaded();
            } else {
                img.addEventListener("load", handleImageEvent);
                img.addEventListener("error", handleImageEvent);
            }
        });

        if (images.length === 0) {
            console.log("No images found with the specified class.");
        }
    } catch (error) {
        console.error("Error in smart crop initialization:", error);
    }
}

let resizeTimeout;
function onWindowResize() {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(initSmartCrop, 200);
}

function observeDOMChanges() {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === "childList") {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === 1 && node.matches(".s18-individual-listing-image")) {
                        console.log("New image added");
                        initSmartCrop();
                    }
                });
            }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });
}

if (document.readyState === "loading") {
    console.log("Document is loading, adding DOMContentLoaded listener");
    document.addEventListener("DOMContentLoaded", function() {
        console.log("DOMContentLoaded event fired, initializing smart crop");
        initSmartCrop();
    });
} else {
    console.log("Document already loaded, initializing smart crop directly");
    initSmartCrop();
    window.addEventListener("resize", onWindowResize);
    observeDOMChanges();
}

function calculatePt(Ps, Wi, Wv, MIN_WIDTH) {
    if (Wi === Wv) {
        return 0.5;
    }
    const Pmin = (Wv / 2 - (Wi * Ps)) / (Wv - Wi);
    const Pfull = 0.5;
    const PfullWeight = (Wv - MIN_WIDTH) / (Wi - MIN_WIDTH);
    const PminWeight = 1 - PfullWeight;
    return (Pfull * PfullWeight) + (Pmin * PminWeight); 
}


// (function() {
//     function initializeSmartCrop() {
//         console.log("Initializing smart_crop");

//         const MIN_WIDTH = 50; // the arbitrary min-width of the image

//         const images = document.querySelectorAll(".s18-individual-listing-image");
//         let loadedCount = 0;

//         function checkAllImagesLoaded() {
//             loadedCount++;
//             console.log(`Loaded count: ${loadedCount}/${images.length}`);
//             if (loadedCount === images.length) {
//                 console.log("All images loaded");
//                 images.forEach(image => {
//                     const Ps = parseFloat(image.dataset.ps);
//                     if (isNaN(Ps)) {
//                         console.log(`Ps value is NaN for image ${image.src}`);
//                         return;
//                     }

//                     const naturalW = image.naturalWidth;
//                     const naturalH = image.naturalHeight;
//                     if (!naturalW || !naturalH) {
//                         console.log(`Natural dimensions not available for image ${image.src}`);
//                         return;
//                     }

//                     const currentH = image.height;
//                     const Wi = naturalW * (currentH / naturalH);
//                     const Wv = image.width;

//                     if ((Ps * Wi) <= (MIN_WIDTH / 2)) {
//                         image.style.objectPosition = `0 50%`;
//                     } else if ((Ps * Wi) >= (Wi - (MIN_WIDTH / 2))) {
//                         image.style.objectPosition = `100% 50%`;
//                     } else {
//                         const Pt = calculatePt(Ps, Wi, Wv, MIN_WIDTH);
//                         image.style.objectPosition = `${Pt * 100}% 50%`;
//                     }
//                 });
//             }
//         }

//         function handleImageEvent(event) {
//             checkAllImagesLoaded();
//             event.target.removeEventListener("load", handleImageEvent);
//             event.target.removeEventListener("error", handleImageEvent);
//         }

//         images.forEach(function(img) {
//             if (img.complete && img.naturalWidth && img.naturalHeight) {
//                 checkAllImagesLoaded();
//             } else {
//                 img.addEventListener("load", handleImageEvent);
//                 img.addEventListener("error", handleImageEvent);
//             }
//         });

//         if (images.length === 0) {
//             console.log("No images found with the specified class.");
//         }
//     }

//     let resizeTimeout;
//     function onWindowResize() {
//         clearTimeout(resizeTimeout);
//         resizeTimeout = setTimeout(initializeSmartCrop, 200);
//     }

//     function observeDOMChanges() {
//         const observer = new MutationObserver((mutations) => {
//             mutations.forEach((mutation) => {
//                 if (mutation.type === "childList") {
//                     mutation.addedNodes.forEach((node) => {
//                         if (node.nodeType === 1 && node.matches(".s18-individual-listing-image")) {
//                             console.log("New image added");
//                             initializeSmartCrop();
//                         }
//                     });
//                 }
//             });
//         });

//         observer.observe(document.body, { childList: true, subtree: true });
//     }

//     document.addEventListener("DOMContentLoaded", function() {
//         console.log("DOMContentLoaded detected by smart crop");
//         initializeSmartCrop();
//         window.addEventListener("resize", onWindowResize);
//         observeDOMChanges();
//     });

//     document.addEventListener("turbo:load", function() {
//         console.log("turbo:load detected by smart crop");
//         initializeSmartCrop();
//     });

//     function calculatePt(Ps, Wi, Wv, MIN_WIDTH) {
//         if (Wi === Wv) {
//             return 0.5;
//         }
//         const Pmin = (Wv / 2 - (Wi * Ps)) / (Wv - Wi);
//         const Pfull = 0.5;
//         const PfullWeight = (Wv - MIN_WIDTH) / (Wi - MIN_WIDTH);
//         const PminWeight = 1 - PfullWeight;
//         return (Pfull * PfullWeight) + (Pmin * PminWeight); 
//     }
// })();